import posthog from 'posthog-js';
import * as config from './config';

const isBrowser = typeof window !== 'undefined';
const isDevelopment = process.env.NODE_ENV === 'development';

if (isBrowser && config.POSTHOG_KEY) {
  posthog.init(config.POSTHOG_KEY, {
    api_host: config.POSTHOG_HOST,
    loaded: (posthog) => {
      if (isDevelopment) posthog.debug();
    },
  });
}

// Define the window interface for TypeScript
interface Window {
  gtag?: any;
  dataLayer?: any;
}

declare var window: Window;

const analytics = {
  /**
   * Track events and send them to Google Analytics
   * @param {String} event Event category
   * @param {Object} options Data to send to Google Analytics
   */
  track(
    event: string,
    options: {
      eventAction?: string;
      eventLabel?: string;
      eventValue?: any;
    } = {}
  ) {
    if (typeof window === 'undefined' || !event) {
      return;
    }

    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', options.eventAction || event, {
        event_category: event,
        event_label: options.eventLabel,
        value: options.eventValue,
      });
    }

    if (typeof window.dataLayer !== 'undefined') {
      window.dataLayer.push({
        event: event,
        data: { ...options },
      });
    }
  },

  /**
   * Record a page view with Google Analytics
   * @param {String} path URL path of the viewed page
   */
  pageView(path: string) {
    if (typeof window === 'undefined') {
      return;
    }

    if (typeof window.dataLayer !== 'undefined') {
      window.dataLayer.push({
        event: 'page_view',
        page: path,
      });
    }

    if (typeof window.gtag !== 'undefined') {
      window.gtag('config', config.GA_TRACKING_ID, {
        page_path: path,
      });
    }
  },

  /**
   * Report an error to Google Analytics
   * @param {Object} err Error object with a message
   * @param {Boolean} fatal Flag to indicate if the error is fatal
   */
  error(err: { message: string }, fatal: boolean = false) {
    if (!process.env.BROWSER) {
      return;
    }

    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'exception', {
        description: err.message,
        fatal: fatal,
      });
    }
  },
};

export default analytics;

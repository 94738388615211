import { useEffect } from 'react';

export const useIntercom = (Intercom: any) => {
  useEffect(() => {
    if (Intercom) {
      const store = window?.localStorage.getItem('persist:root');
      const profile = store ? JSON.parse(store)?.state?.user?.profile : null;

      Intercom('boot', {
        app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
        email: profile?.email,
        user_id: profile?.id,
        name: profile?.name,
      });
    }
  }, [Intercom]);
};

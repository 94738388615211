export const PROD_PLANS = {
  BASIC: 'prod_P5iVc90TWMdPiE',
  STARTER: {
    month: 'prod_PzEHJVcZo20w5z',
    year: 'prod_PdMyuwkq6L4z9P',
  },
  PRO: {
    month: 'prod_PdMxyORmI8udN8',
    year: 'prod_PdMyS6Q6ah2RT4',
  },
} as const;

export const DEV_PLANS = {
  BASIC: 'prod_P2eJNBXtzQmLah',

  STARTER: {
    month: 'prod_PzEI4M75y0XRuj',
    year: 'prod_PdMCmYUT7Id2ek',
  },
  PRO: {
    month: 'prod_P2eIqdGpPvBIZh',
    year: 'prod_Pd3xagh08I3BQF',
  },
};

export const STYLES = [
  // {
  //   name: 'None',
  //   image: '/images/misc/create/00.png',
  // },
  {
    name: 'Watercolor',
    image: '/images/misc/create/04.png',
  },
  {
    name: 'Ilya',
    image: '/images/misc/create/02.png',
  },
  {
    name: 'Risograph',
    image: '/images/misc/create/03.png',
  },
  {
    name: 'Sketch',
    image: '/images/misc/create/01.png',
  },
];

export const RANDOM_PROMPTS = [
  'A macro shot of a human eye',
  'A beautiful golden retriever',
  'A beautiful woman, risograph, retro printer style, black background, dual tones, character design by togas-hi yoshihiro and yoji shinkawa and guweiz and Stanley artgerm and tom bagshaw, short hair, face and eyes, intricate details, aesthetics, watercolor, ink art, manga',
  'Vibrant, colourful oil painting of parisian cafe on the streets of Paris',
  'Retro-style photo of a summer italian seaside town with colourful houses and boats. The water is crystal clear and shimmering. It looks very inviting and vibrant.',
  'In an anime-style, a beautiful and vibrant painting of a dream of a universe',
  'An anime-style romantic sunset dream set among the stars',
  'A lion, mid-roar',
  'A vibrant purple rose up-close, macro-shot',
  'A utopian city with sustainable, high-tech architecture and lush greenery.',
];

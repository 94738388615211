import type { AppProps } from 'next/app';
import { type Layouts } from '@/components/layout';
import { ClerkProvider } from '@clerk/nextjs';
import { SSRProvider } from '@nex/labs';
import dynamic from 'next/dynamic';
import Link from 'next/link';

import { useRouter } from 'next/router';
import { useIntercom } from '@/components/misc/use-intercom';
import { useEffect } from 'react';
import posthog from 'posthog-js';
import analytics from '@lib/analytics';

import '@nex/labs/index.css';
import '@nex/labs/styles/tailwind.scss';
import { QueryClientProvider, Hydrate } from '@tanstack/react-query';
import { queryClient } from '@lib/query-client';
import { PostHogProvider } from 'posthog-js/react';

declare global {
  interface Window {
    Intercom: any;
  }
}

const BaseLayout = dynamic(
  () => import('@/components/layout/layout').then((mod) => mod.PreRenderLayout),
  {
    ssr: false,
  }
);

export default function App({
  Component,
  layoutProps: _layoutProps,
  pageProps,
}: AppProps & {
  layoutProps: {
    title?: string;
    seo?: {
      title?: string;
      description?: string;
      image?: string;
    };
    layout: keyof typeof Layouts;
  };
}) {
  const router = useRouter();

  const Intercom = typeof window !== 'undefined' ? window?.Intercom : null;
  const layoutProps =
    (Component as any)?.layoutProps?.(pageProps) || _layoutProps;

  useIntercom(Intercom);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      posthog?.capture('$pageview');
      analytics.pageView(url);
      Intercom?.('update');
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, Intercom]);

  return (
    <ClerkProvider
      signInFallbackRedirectUrl={'/'}
      signUpFallbackRedirectUrl={'/onboarding'}
    >
      <PostHogProvider client={posthog}>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <SSRProvider linkFactory={Link}>
              <BaseLayout
                seo={{
                  title: layoutProps?.title,
                  ...layoutProps?.seo,
                }}
                layout={layoutProps?.layout}
              >
                <Component {...pageProps} />
              </BaseLayout>
            </SSRProvider>
          </Hydrate>
        </QueryClientProvider>
      </PostHogProvider>
    </ClerkProvider>
  );
}

App.getInitialProps = async ({
  Component,
  ctx,
}: {
  Component: any;
  ctx: {
    asPath: string;
  };
}) => {
  const { asPath } = ctx as any;

  return {
    pageProps: {
      location: asPath,
      ...(Component.getStaticProps ? await Component.getStaticProps(ctx) : {}),
    },
  };
};

import { DEV_PLANS, PROD_PLANS } from './constants';

export const APP_URL = process.env.NEXT_PUBLIC_APP_URL;
export const API_URL =
  process.env.NEXT_PUBLIC_API_URL! || 'http://localhost:3000/api';
export const GOOGLE_AUTH_ID = process.env.NEXT_PUBLIC_GOOGLE_AUTH_ID!;
export const STRIPE_KEY = process.env.NEXT_PUBLIC_STRIPE_KEY!;
export const PLANS =
  API_URL !== 'https://api.nex.art/v1' ? DEV_PLANS : PROD_PLANS;
export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS;
export const GA_TAG = process.env.NEXT_PUBLIC_GA_TAG;
export const TWITTER_API_KEY = process.env.NEXT_PUBLIC_TWITTER_CLIENT_ID;
export const POSTHOG_KEY = process.env.NEXT_PUBLIC_POSTHOG_KEY;
export const POSTHOG_HOST =
  process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://app.posthog.com';

export const PUSHER_API_KEY = process.env.NEXT_PUBLIC_PUSHER_API_KEY || '';
export const PUSHER_CLUSTER = process.env.NEXT_PUBLIC_PUSHER_CLUSTER || '';
